import { PriceCalculation } from "./PriceCalculate"

export const WhatsappTextGenerator = (data) => {
    var text = `Halo Bang Jamms, Aku mau order ${data.title} !!\n\nData Order : \n`
    Object.keys(data.data).forEach((val) => {
        if(val !== 'total'){
            text += val.split('_').join(' ') + ' = '
            text += data.data[val]
            text += '\n'
        }
    })

    text += '\nData Kuantitas : \n'
    Object.keys(data.data.total).forEach((val) => {
        if(data.data.total[val] > 0){
            text += val.split('_').join(' ') + ' = '
            text += data.data.total[val] + '\n'
        }
    })

    var price;
    if(data.title !== 'brand kit'){
        var qty = 0;
        Object.keys(data.data.total).forEach((val) => {
            qty += data.data.total[val]
        })
        price = PriceCalculation(data.title , qty)
    }else{
        price = PriceCalculation(data.title , data.data.total)
    }

    text += '\nEstimasi Harga = Rp. ' + price

    return text;

}
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Footer extends Component {
    render() {
        return (
            <div className="jamms-bg-light">
                <div className="container border-top py-5">
                    <div className="row mt-4">
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                SITEMAP
                            </p>
                            <Link to='/pricelist' className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                    Pricelist
                                </p>
                            </Link>
                            {/* <Link className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    Portfolio
                                </p>
                            </Link> */}
                            <Link to='/order/kaos' className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    Pesan Kaos
                                </p>
                            </Link>
                            <Link to='/order/totebag' className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    Pesan Totebag
                                </p>
                            </Link>
                           
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                OUR STORE
                            </p>
                           
                                <p className="jamms-font-12 p-0 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                    <a target='_blank' rel="noopener noreferrer"  href="https://g.page/jammscloth?share" className='jamms-link p-0 m-0'>
                                        Offline Store
                                    </a>
                                </p>
                           
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    <a target='_blank' rel="noopener noreferrer" href="https://www.tokopedia.com/jamms" className='jamms-link p-0 m-0'>
                                        Tokopedia
                                    </a>
                                </p>
                               
                            
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                CONTACT US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6287764906963" className='jamms-link p-0 m-0'>
                                0877 6490 6963 (Admin 1)
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6282226840026" className='jamms-link p-0 m-0'>
                                0822 2684 0026 (Admin 2)
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="mailto:admin@jamms.store" className='jamms-link p-0 m-0'>
                                admin@jamms.store
                                </a>
                            </p>
                            
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                FOLLOW US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/jamms.id/" className='jamms-link p-0 m-0'>
                                Instagram
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://web.facebook.com/jamms.id" className='jamms-link p-0 m-0'>
                                Facebook
                                </a>
                            </p>
                            
                            
                        </div>
                        
                    </div>
                    <div className="text-center mt-4 jamms-font-12  text-secondary">
                        © 2020 Jamms Artwear. All Rights Reserved
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer

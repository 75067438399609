import React, { Component } from 'react'
import './Supports/Css/Utils.css'
import './Supports/Css/Navbar.css'
import Navbar from './Components/Navbar'
import LandingPage from './Pages/LandingPage'
import Footer from './Components/Footer'
import { BrowserRouter,Route,Switch } from 'react-router-dom'
import Register from './Pages/Register'
import PriceCalculator from './Pages/Order'
import PriceCalculatorLanjut from './Pages/OrderDetail'
import OrderConfirmation from './Pages/OrderConfirmation'
import SyaratDanKetentuan from './Pages/SyaratDanKetentuan'
import PricelistPage from './Pages/PricelistPage'
import { Helmet } from 'react-helmet'
import PageNotFound from './Pages/PageNotFound'
import ReactGA from 'react-ga';
import WithPageView from './Components/WithPageView'
ReactGA.initialize('UA-173537824-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Helmet>
              <title>Jamms Artwear || High Quality Cloth Maker</title>
              <meta name="description" content="Tempat sablon berkualitas, Bikin Kaos, Totebag, Hoodie untuk komunitasmu disini. Pesan Online, gratis ongkir area Purwokerto dan Sekitarnya"/>
          </Helmet>
          <Navbar />
          
          <Switch>
            <Route exact path='/' component={WithPageView(LandingPage)} />
          <Route path='/register' component={WithPageView(Register)} />
          <Route path='/order/:jenis/confirmation' component={WithPageView(OrderConfirmation)} />
          <Route path='/order/:jenis' component={WithPageView(PriceCalculatorLanjut)} />
          <Route path='/order' component={WithPageView(PriceCalculator)} />
          <Route path='/pricelist' component={WithPageView(PricelistPage)} />
          <Route path='/syarat-dan-ketentuan' component={WithPageView(SyaratDanKetentuan)} />
          <Route path='*' component={WithPageView(PageNotFound)} />
          </Switch>
           
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default App

import React, { Component } from 'react'

export class Register extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
            <div className='py-5'>
                <div className="py-5">
                    <div className="container mt-4">
                        <h1 className='jamms-main text-center jamms-secondary'>Buat Akun Baru</h1>
                        
                        <p className='text-secondary font-weight-light text-center jamms-font-16'>Daftarkan dirimu & dapatkan banyak keuntungan <br/> dengan menjadi member Jamms Artwear</p>
                        <div className="row mt-5 px-3 justify-content-center">
                            <div className="col-md-6 border rounded p-5 jamms-shadow jamms-bg-secondary">
                                <input type="text" className='form-control font-weight-light jamms-font-14' placeholder='Masukan Nomor HP atau Email'/>
                                <input type="text" className='form-control font-weight-light jamms-font-14 mt-3' placeholder='Masukan Password'/>
                                <input type="text" className='form-control font-weight-light jamms-font-14 mt-3' placeholder='Ulangi Password'/>
                                <input type="button" value="Submit" className='mt-5 jamms-shadow btn w-100 jamms-bg-main jamms-light'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register

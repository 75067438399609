import { faArrowRight, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'


export class BrandKitForm extends Component {
    state = {
        title : null,
        total : {
            price_tag : 0,
            sablon_merk:0,
            paper_bag_packaging : 0,
        },
        warna : 1,
        hasDesain:true,
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }

    onLanjutClick = () => {
        var totalValidate = false
        Object.keys(this.state.total).forEach((prop) => {
            if(this.state.total[prop] > 0){
                totalValidate = true
            }
        })

        if(totalValidate){
            var data = {
                title : 'brand kit',
                created_at : new Date(),
                data : {
                    total : this.state.total,
                    desain : this.state.hasDesain ? 'sudah ada' : 'belum ada'
                }
               
            }

            if(this.state.hasDesain){
                data.data.jumlah_warna_desain = this.state.warna
            }
            
            try {
                localStorage.setItem('order',JSON.stringify(data))
                window.location = '/order/brand%20kit/confirmation'
            } catch (error) {
                alert(error.message)
            }
            
        }else{
            alert('Kuantitas tidak boleh 0 semuanya')
        }
    }
    render() {
        
        return (
            <div className='py-5'>
                 <Helmet>
                    <title>Form Pesan Brand Kit di Jamms</title>
                    <meta name="description" content="Jadikan brand mu lebih kredibel dengan paket brand kit dari jamms, harga terjangkau dan jelas"/>
                </Helmet>
                <div className="py-5">
                    <div className="container mt-4">
                        <h1 className='jamms-secondary text-center'>Form Bikin Brand Kit</h1>
                        <p className='font-weight-light text-center text-secondary'>Bikin brand elo lebih keren dengan Bang Jamms</p>
                    </div>

                    <div className="container mt-4">
                        <div className="row px-4 justify-content-center">
                            <div className="col-md-6 border jamms-shadow p-5">
                                <h4 className='mt-4'>Jumlah Item</h4>

                                    {
                                        Object.keys(this.state.total).map((val) => {
                                            return(
                                                <div>
                                                    <FontAwesomeIcon onClick={() => {
                                                        var total = this.state.total
                                                         
                                                        if(total[val] >= 10){
                                                            total[val] -=10
                                                            this.setState({total: total})
                                                        }
                                                    }} className='jamms-main jamms-clickable-el' icon={faMinusCircle} />
                                                     <FontAwesomeIcon onClick={() => {
                                                        var total = this.state.total
                                                         
                                                        if(total[val] !==  0){
                                                            total[val] --
                                                            this.setState({total: total})
                                                        }
                                                    }} className='jamms-main jamms-clickable-el' icon={faMinusCircle} />
                                                    <span className='mx-3 jamms-secondary'>{this.state.total[val]}</span>
                                                    <FontAwesomeIcon onClick={
                                                        () => {
                                                            var total = this.state.total
                                                            total[val] ++
                                                            this.setState({total: total})
                                                        }
                                                    } className='jamms-main jamms-clickable-el'  icon={faPlusCircle}/>
                                                    <FontAwesomeIcon onClick={
                                                        () => {
                                                            var total = this.state.total
                                                            total[val] +=10
                                                            this.setState({total: total})
                                                        }
                                                    } className='jamms-main jamms-clickable-el'  icon={faPlusCircle}/>
                                                    <span className='ml-3 text-capitalize text-secondary jamms-font-12 font-weight-light'>
                                                        {val.toLowerCase().split('_').join(' ')}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                              
                                

                                <h4 className='mt-4'>Sudah Punya Desain ?</h4>

                                <div className="form-check">
                                    <input onClick={() => this.setState({hasDesain : true})} className="form-check-input" name='isdesain' type="radio" id='sudah' value='sudah' checked={this.state.hasDesain}/>
                                    <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={'sudah'}>
                                        {'sudah'}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={() => this.setState({hasDesain : false})} className="form-check-input" name='isdesain' type="radio" id='belum' value='belum' checked={this.state.hasDesain ===false} />
                                    <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={'belum'}>
                                        {'belum'}
                                    </label>
                                </div>
                                
                                {
                                    this.state.hasDesain ? 
                                    <span>
                                        <h4 className='mt-4'>Jumlah Warna Dalam Desain</h4>
                                        <div>
                                            <FontAwesomeIcon onClick={() => this.state.warna !== 1 && this.setState({warna :   this.state.warna -1})} className='jamms-main jamms-clickable-el'  icon={faMinusCircle}/>
                                            <span className='mx-3 jamms-secondary'>{this.state.warna}</span>
                                            <FontAwesomeIcon onClick={() => this.setState({warna : this.state.warna +1})} className='jamms-main jamms-clickable-el'  icon={faPlusCircle}/>
                                        </div>
                                    </span>
                                    :
                                    false
                                }


                               
                                <div className='mt-5'>
                                <span onClick={this.onLanjutClick}  className='jamms-bg-secondary py-2 px-4 rounded text-white jamms-clickable-el'>
                                    lanjut
                                    <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
                                </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BrandKitForm

import React, { Component } from 'react'

export class SyaratDanKetentuan extends Component {
    render() {
        return (
            <div className='py-5'>  
                <div className="py-5">
                    <div className="py-5">
                        <div>
                            <div className="container">
                                <h1>Syarat dan Ketentuan Penggunaan Platform</h1>
                                <p className='font-weight-light jamms-font-12'>Berlaku efektif sejak 07 September 2020</p>
                                <p className='font-weight-light jamms-font-12'>Syarat dan Ketentuan ini merupakan perjanjian antara pengguna (“Anda”) dan Jamms Artwear (“Kami”). Syarat dan Ketentuan ini mengatur Anda saat mengakses dan menggunakan aplikasi, situs web (www.jamms.store dan situs web lain yang Kami kelola), fitur, teknologi, konten dan produk yang Kami sediakan (selanjutnya, secara Bersama-sama disebut sebagai “Platform”).</p>
                                <p>Harap membaca Syarat dan Ketentuan ini secara seksama sebelum Anda mulai menggunakan Platform Kami, karena peraturan ini berlaku pada penggunaan Anda terhadap Platform Kami.</p>
                                <p className='font-weight-light jamms-font-12'>Anda harus berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan agar Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat dan Ketentuan ini. Jika Anda tidak memenuhi ketentuan tersebut,maka tindakan Anda mendaftar, mengakses, menggunakan atau melakukan aktivitas lain dalam Platform Kami harus dalam sepengetahuan, pengawasan dan persetujuan yang sah dari orang tua atau wali atau pengampu Anda. Orang tua, wali atau pengampu yang memberikan persetujuan bagi Anda yang berusia di bawah 18 (delapan belas) tahun bertanggung jawab secara penuh atas seluruh tindakan Anda dalam penggunaan dan akses terhadap Platform.</p>
                                <p className='font-weight-light jamms-font-12'>Dengan mendaftar dan/atau menggunakan Platform Kami, maka Anda dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia di bawah 18 tahun) dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat dan Ketentuan ini.</p>
                                <p className='font-weight-light jamms-font-12'>Dengan menyetujui Syarat dan Ketentuan ini, Anda juga menyetujui Syarat dan Ketentuan tambahan, termasuk Syarat dan Ketentuan atas setiap Layanan, dan perubahannya yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini (selanjutnya, Syarat dan Ketentuan, Syarat dan Ketentuan tambahan, dan perubahannya secara bersama-sama disebut sebagai “Syarat dan Ketentuan”). Meskipun merupakan satu kesatuan, Syarat dan Ketentuan tambahan akan berlaku dalam hal terdapat perbedaan dengan Syarat dan Ketentuan</p>
                                <p className='font-weight-light jamms-font-12'>Jika Anda tidak menyetujui Syarat dan Ketentuan ini, Kami berhak untuk menghentikan akses dan penggunaan Anda terhadap Platform Kami.</p>
                                <h5>A. PEMBUKAAN AKUN ANDA</h5>
                                <p className='font-weight-light jamms-font-12'>Sebelum menggunakan Platform, Anda menyetujui Syarat dan Ketentuan ini dan Kebijakan Privasi, dan mendaftarkan diri Anda dengan memberikan informasi yang Kami butuhkan. Saat melakukan pendaftaran, Kami akan meminta Anda untuk memberikan nama lengkap, alamat surat elektronik dan nomor telepon genggam pribadi Anda. Kami juga dapat menghentikan penggunaan Platform jika dikemudian hari data-data yang Anda berikan kepada Kami terbukti tidak benar.</p>
                                <p className='font-weight-light jamms-font-12'> 
                                Sistem Kami akan membuatkan akun pada Platform (“Akun”) untuk Anda yang dapat digunakan untuk menggunakan Platform dan memesan layanan melalui Platform.
                                <br/> <br/>
                                Dalam hal Anda telah keluar dari Akun Anda, maka Anda perlu memasukan alamat surat elektronik yang ada berikan pada saat mendaftarkan diri Anda dan memasukan password.
                                <br/> <br/>
                                Akun Anda hanya dapat digunakan oleh Anda, sehingga Anda tidak dapat mengalihkannya kepada orang lain dengan alasan apa pun. Kami berhak menolak untuk memfasilitasi Layanan jika Kami mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa Anda telah mengalihkan atau membiarkan Akun Anda digunakan oleh orang lain.
                                <br/> <br/>
                                Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar, alamat surat elektronik terdaftar, nomor telepon genggam terdaftar sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang ada akibat kelalaian Anda dalam menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia di bawah 18 (delapan belas) tahun). Dengan demikian, Kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui Akun Anda sebagai permintaan yang sah dari Anda. Anda harap segera memberitahukan kepada Kami jika Anda mengetahui atau menduga bahwa Akun Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.
                                </p>
                                <h5>B. INFORMASI PRIBADI ANDA</h5>
                                <p className='font-weight-light jamms-font-12'>
                                Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat surat elektronik, dan nomor telepon genggam Anda yang Anda berikan ketika Anda membuka Akun tunduk pada Kebijakan Privasi, yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini.
                                </p>
                                <h5>C. AKSES TERHADAP PLATFORM KAMI</h5>
                                <p className='font-weight-light jamms-font-12'>

                                Kami tidak menjamin bahwa Platform Kami, maupun konten di dalamnya, akan selalu tersedia atau tanpa gangguan. Izin untuk mengakses Platform Kami bersifat sementara. Kami dapat menangguhkan, menarik, memberhentikan, maupun mengganti bagian mana pun dari Platform Kami tanpa pemberitahuan sebelumnya. Kami tidak bertanggung jawab atas alasan apa pun yang membuat Platform Kami tidak tersedia pada waktu atau periode tertentu.
                                <br/><br/>
                                Anda bertanggung jawab untuk membuat semua pengaturan yang dibutuhkan untuk mendapatkan akses terhadap Platform Kami.
                                </p>
                                <h5>D. PENGGUNAAN YANG DILARANG</h5>
                                <p className='font-weight-light jamms-font-12'>
                                Anda hanya diperbolehkan menggunakan Platform kami untuk tujuan-tujuan yang sah menurut hukum. Anda tidak boleh menggunakan Platform Kami:
                                <br/><br/>
                                <ol>
                                    <li>
                                        Dengan cara-cara yang melanggar hukum dan peraturan lokal, nasional, maupun internasional yang berlaku.
                                    </li>
                                    <li>
                                        Dengan cara-cara yang melanggar hukum atau menipu, atau memiliki tujuan atau dampak yang melanggar hukum atau menipu.
                                    </li>
                                    <li>
                                        Untuk tujuan membahayakan atau mencoba mencelakakan anak di bawah umur dengan cara apa pun.
                                    </li>
                                    <li>
                                        Mengirim, secara sadar menerima, mengunggah, mengunduh, menggunakan, atau menggunakan kembali materi yang tidak sesuai dengan standar konten Kami.
                                    </li>
                                    <li>
                                        Menyebarkan atau mengirimkan materi iklan atau promosi yang tidak diinginkan atau tidak sah, serta bentuk permintaan serupa lainnya (seperti spam).
                                    </li>
                                    <li>
                                        Dengan sengaja meneruskan data, mengirim atau mengunggah materi yang mengandung virus, trojan, worm, logic bomb, keystroke loggers, spyware, adware, maupun program berbahaya lainnya atau kode komputer sejenis yang dirancang untuk memberikan efek merugikan terhadap pengoperasian perangkat lunak atau perangkat keras apa pun.
                                    </li>
                                </ol>
                                Anda juga setuju: <br/><br/>
                                <ol>
                                    <li>
                                        Untuk tidak mereproduksi, menggandakan, menyalin, atau menjual kembali bagian mana pun dari Platform Kami yang bertentangan dengan ketentuan dalam Syarat dan Ketentuan Platform Kami.
                                        <li>Untuk tidak mengakses tanpa izin, mengganggu, merusak, atau mengacak-acak:</li>
                                        <ol type='a' >
                                            <li>bagian mana pun dari Platform Kami;</li>
                                            <li>peralatan atau jaringan di mana Platform Kami tersimpan;</li>
                                            <li>perangkat lunak apa pun yang digunakan dalam penyediaan Platform Kami; </li>
                                            <li>peralatan atau jaringan atau perangkat lunak yang dimiliki oleh pihak ketiga mana pun.</li>
                                        </ol>
                                        <li>Apabila Anda adalah seorang pegawai negeri atau penyelenggara negara, dalam menggunakan Platform Kami, Anda setuju untuk mematuhi ketentuan peraturan perundang-undangan khususnya yang mengatur tentang penerimaan gratifikasi serta ketentuan lain yang berlaku di lingkungan lembaga anda.</li>
                                        <li>Apabila Anda adalah seorang pendidik atau tenaga kependidikan, dalam menggunakan Platform Kami, anda setuju untuk mematuhi ketentuan peraturan perundang-undangan terkait pengelolaan dan penyelenggaraan pendidikan, terutama terkait kegiatan sosialisasi, pemasaran, distribusi, maupun penjualan program/produk dari Platform Kami kepada peserta didik di satuan pendidikan formal dimana Anda mengajar atau bekerja dengan suatu imbalan dalam bentuk apa pun.</li>

                                    </li>
                                </ol>
                                </p>
                                <h5>E. HAK KEKAYAAN INTELEKTUAL</h5>
                                <p className='font-weight-light jamms-font-12'>
                                Platform kami, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar atas nama Kami. Kami memiliki seluruh hak dan kepentingan atas Platform, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait.
                                <br/><br/>
                                Anda dapat mengunduh ekstrak dari halaman tertentu dari Platform Kami untuk kegunaan pribadi selama masa berlangganan Anda.
                                <br/><br/>
                                Anda tidak boleh mengubah salinan dalam bentuk kertas maupun digital dari materi apa pun yang telah Anda cetak atau unduh dengan cara apa pun, dan Anda tidak boleh menggunakan ilustrasi, foto-foto, potongan video atau audio, maupun grafis lain secara terpisah dari teks pendampingnya.
                                <br/><br/>
                                Anda dilarang untuk:
                                <ol>
                                    <li>
                                    menyalin, mengubah, mencetak, mengadaptasi, menerjemahkan, menciptakan karya tiruan dari, mendistribusikan, memberi lisensi, menjual, memindahkan, menampilkan secara publik, menunjukkan secara publik, menggandakan, mengirimkan, menyiarkan lewat media online maupun offline, memotong, membongkar, atau sebaliknya mengeksploitasi bagian mana pun dari Platform Kami, termasuk namun tidak terbatas pada konten-konten dan materi-materi berbayar pada Platform, baik secara fisik maupun digital, dalam masa berlangganan maupun selesai berlangganan Platform;
                                    </li>
                                    <li>
                                    memberi lisensi, lisensi turunan, menjual, menjual ulang, memindahkan, menetapkan, mendistribusikan, atau sebaliknya mengeksploitasi atau membagikan secara komersial Platform Kami dan/atau perangkat lunak lain yang terasosiasi dengan Platform Kami dengan cara apa pun;
                                    </li>
                                    <li>
                                    menciptakan ‘link’ internet menuju situs web Kami, atau menyadur (frame), atau mengkomputasi (mirror) perangkat lunak mana pun pada server (server) atau perangkat nirkabel atau perangkat yang terhubung ke internet lainnya;
                                    </li>
                                    <li>
                                    merekayasa balik atau mengakses Platform Kami guna (a) membangun produk atau jasa yang kompetitif, (b) membangun produk berdasarkan ide, fitur, fungsi, maupun grafis yang serupa dengan Platform Kami, atau (c) menyalin ide, fitur, fungsi, atau grafis pada Platform Kami;
                                    </li>
                                    <li>
                                    meluncurkan program atau skrip otomatis termasuk, namun tidak terbatas pada, web spider, web crawler, robot web, web ant, pengindeksan web, bot, virus, worm, atau program apa pun yang dapat meningkatkan permintaan server per detik, atau membuat beban terlalu berat yang mengganggu operasi dan/atau kinerja Platform Kami;
                                    </li>
                                    <li>
                                    menggunakan robot, spider, aplikasi pencarian atau pengambilan situs, maupun perlengkapan dan proses manual atau otomatis lain untuk mengambil, membuat indeks, menambang data, atau dengan cara apa pun menggandakan atau menghindari struktur navigasi atau tampilan Platform Kami maupun kontennya;
                                    </li>
                                    <li>
                                    memasang, mendistribusikan, atau menggandakan dengan cara apa pun materi dengan hak cipta, merek dagang, atau informasi kepemilikan lain tanpa sebelumnya memperoleh persetujuan dari pemilik hak kepemilikan;
                                    </li>
                                    <li>
                                    menghapus pemberitahuan hak cipta, merek dagang, atau hak kepemilikan lainnya yang terkandung dalam Platform Kami. Tidak ada lisensi atau hak yang diberikan kepada Anda secara implisit atau sebaliknya berdasarkan hak kekayaan intelektual yang dimiliki atau dikendalikan oleh kami dan pemberi lisensi kami, kecuali lisensi dan hak yang secara tersurat diberikan dalam Persyaratan Penggunaan ini. Anda tidak boleh menggunakan bagian mana pun dari konten pada Platform Kami untuk tujuan komersial tanpa sebelumnya memperoleh lisensi untuk melakukannya dari Kami atau pemberi lisensi Kami.
                                    </li>
                                </ol>
                                <br/><br/>
                                Kami berhak untuk melakukan investigasi maupun menuntut bentuk pelanggaran apa pun terhadap Persyaratan Penggunaan di atas sesuai dengan ketentuan hukum yang berlaku. Kami dapat melibatkan dan bekerja sama dengan pihak yang berwenang dalam menuntut pengguna yang melanggar Persyaratan Penggunaan ini. Anda mengakui bahwa Kami tidak diwajibkan untuk mengawasi akses Anda terhadap Platform Kami, tapi Kami berhak untuk melakukannya dengan tujuan mengoperasikan Platform Kami, memastikan kepatuhan Anda dengan Syarat dan Ketentuan ini, atau untuk menaati hukum yang berlaku atau keputusan pengadilan, lembaga administratif, maupun badan pemerintahan lainnya.
                                </p>
                                <h5>F. TANGGUNG JAWAB ANDA</h5>
                                <p className='font-weight-light jamms-font-12'>
                                Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Platform. Anda harus berperilaku dengan hormat dan tidak boleh terlibat dalam perilaku atau tindakan yang tidak sah, mengancam atau melecehkan ketika menggunakan Platform.
                                <br/><br/>
                                Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Platform melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Syarat dan Ketentuan ini, Kebijakan Privasi atau peraturan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan anti pencucian uang, anti pendanaan terorisme, aktivitas kriminal, penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada kegiatan phishing dan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain manapun atau yang dapat atau dianggap dapat merusak reputasi Kami.
                                <br/><br/>
                                Kami tidak menjamin bahwa Platform Kami akan aman atau terbebas dari bug atau virus. Anda bertanggung jawab untuk mengatur teknologi informasi, program komputer, serta platform yang Anda gunakan untuk mengakses Platform Kami. Anda harus menggunakan perangkat lunak anti virus Anda sendiri.

                                </p>

                                <h5>G. KETENTUAN LAINNYA</h5>
                                <p className='font-weight-light jamms-font-12'>
                                Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini merupakan perjanjian dalam bentuk elektronik dan tindakan Anda menekan tombol ‘daftar’ saat pembukaan Akun atau tombol ‘masuk’ saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami sehingga keberlakuan Syarat dan Ketentuan ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Platform oleh Anda.
                                <br/><br/>
                                Kami dapat merevisi Persyaratan Penggunaan ini kapan pun dengan mengubah halaman ini. Mohon kunjungi kembali halaman ini dari waktu ke waktu guna memperhatikan ada tidaknya perubahan yang Kami buat, karena perubahan tersebut akan mengikat Anda.
                                <br/><br/>
                                Kami dapat memperbaharui Platform dari waktu ke waktu serta mengubah kontennya kapan pun. Meskipun demikian, perlu diketahui bahwa Platform Kami dapat memiliki konten yang tidak diperbarui pada waktu tertentu, dan Kami tidak bertanggung jawab untuk memperbaruinya. Kami tidak menjamin bahwa Platform Kami, maupun konten yang terkandung di dalamnya, dapat bebas sepenuhnya dari kesalahan atau kelalaian.
                                <br/><br/>
                                Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Syarat dan Ketentuan atau Kebijakan Privasi yang dibuat dalam bentuk elektronik.
                                <br/><br/>
                                Anda tidak dapat mengalihkan hak Anda berdasarkan Syarat dan Ketentuan ini tanpa persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat mengalihkan hak Kami berdasarkan Syarat dan Ketentuan ini setiap saat kepada pihak lain tanpa perlu mendapatkan persetujuan terlebih dahulu dari atau memberikan pemberitahuan sebelumya kepada Anda.
                                <br/><br/>
                                Bila Anda tidak mematuhi atau melanggar ketentuan dalam Syarat dan Ketentuan ini, dan Kami tidak mengambil tindakan secara langsung, bukan berarti Kami mengesampingkan hak Kami untuk mengambil tindakan yang diperlukan di kemudian hari.
                                <br/><br/>
                                Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Platform atau setelah berakhirnya perjanjian ini antara Anda dan Kami.
                                <br/><br/>
                                Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi ketentuan lainnya.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SyaratDanKetentuan

import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from "react-helmet";

const JenisSablon = ['kaos','totebag','brand kit']

export class PriceCalculator extends Component {
    state ={
        selectedOption : 'kaos',
        isRedirect : false
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }

    onBtnLanjutClick = () => {
        this.state.selectedOption
        &&
        this.setState({isRedirect : true})
    }
    render() {
        if(this.state.isRedirect){
            var linkto = this.state.selectedOption
            linkto = encodeURI(linkto)
            return(
                <Redirect to={'/order/' + linkto} />
            )
        }
        return (
            <div className='py-5'>
            <Helmet>
                <title>Pesan Sekarang Juga</title>
                <meta name="description" content="Bikin kaos atau totebag dengan kualitas terbaik disini!! Gratis ongkir area Purwokerto"/>
            </Helmet>
                <div className="py-5">
                    <div className="container mt-4">
                        <h1 className='jamms-secondary text-center'>Yukk Nyablon Di Jamms</h1>
                        <p className='font-weight-light text-center text-secondary'>High Quality Cloth Maker</p>
                    </div>

                    <div className="container mt-4">
                        <div className="row px-4 justify-content-center">
                            <div className="col-md-8 border jamms-shadow p-5">
                                <h4>Mau Bikin Apa??</h4>
                               {
                                   JenisSablon.map((val) => {
                                       return(
                                        <div className="form-check">
                                        <input onClick={() => this.setState({selectedOption : val})} ref={val} className="form-check-input" name='jenis' type="radio" id={val} value={val} checked={this.state.selectedOption === val}/>
                                        <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={val}>
                                            {val}
                                        </label>
                                        
                                        </div>
                                       )
                                   })
                               }
                                <div className='mt-4'>
                                <span  onClick={this.onBtnLanjutClick} className='jamms-bg-secondary py-2 px-4 rounded text-white jamms-clickable-el'>
                                    lanjut
                                    <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
                                </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PriceCalculator

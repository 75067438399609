import React, { Component } from 'react'

export class PaketBrandKit extends Component {
    render() {
        return (
            <div className="container">
                                <div className="mt-5">
                                    <div className="row justify-content-center">

                                        <div className="col-md-6 px-3 mt-4 mt-md-0">
                                            <div className='rounded jamms-shadow jamms-bg-light py-4'>
                                                <div className='py-3 '>
                                                    <h2 className='jamms-font-second p-0 m-0 jamms-secondary'>Paket Brand Kit</h2>
                                                </div>

                                                <div className='pt-3 px-4 jamms-font-14 text-center'>
                                                    <table className='table'>
                                                        <thead className='thead-dark'>
                                                            <tr>
                                                                <th>Nama Paket</th>
                                                                <th>Harga Per Item</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Price Tag Kaos</td>
                                                                <td>IDR 0.5K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sablon Merk Kaos</td>
                                                                <td>IDR 2K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paper Bag Packaging</td>
                                                                <td>IDR 1K</td>
                                                            </tr>
                                                        
                                                        </tbody>
                                                    </table>
                                                
                                                    
                                                </div>
                                                <div className=' pb-3 px-4 jamms-font-12 text-secondary text-left'>
                                                    <p className='p-0 m-0  font-weight-light mt-2'>
                                                    - Price tag menggunakan kertas karton tebal <br/>
                                                    - Sablon merk belakang leher menggunakan tinta plastisol <br/>
                                                    - Paper Bag Packaging Menggunakan kertas Samson <br/>
                                              
                                                    </p>
                                                
                                                </div>
                                            
                                            
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>
        )
    }
}

export default PaketBrandKit

import React, { Component } from 'react'
import PaketKaos from '../Components/PaketKaos'
import PaketTotebag from '../Components/PaketTotebag'
import PaketBrandKit from '../Components/PaketBrandKit'
import { Helmet } from 'react-helmet'
const dataPackage = ['Kaos','Totebag','Brand Kit']

export class PricelistPage extends Component {
    state = {
        activePackage : 0
    }
    render() {
        return (
            <div className='py-5'>
                <Helmet>
                    <title>Daftar Harga Sablon di Jamms </title>
                    <meta name="description" content="Bikin Kaos, Totebag dan Brand Kit dengan kualitas terbaik dan harga yang Terjangkau, pesan sekarang juga dan dapatkan gratis onkir area Purwokerto"/>
                </Helmet>
                <div className="py-5">
                    <div ref={(el) => { this.priceList = el; }} className='jamms-package-sablon-container'>
                        <div className="container py-5">
                            <div className='text-center'>
                                <h1 className='jamms-font-second jamms text-center d-inline'>Paket Sablon</h1>
                                <p className='text-secondary'>Paket beragam jenis sablon dengan harga terjangkau !!</p>
                                <div className='mt-4'>
                                    <div>
                                        {
                                            dataPackage.map((val,index) => {
                                                var classNameView = 'py-2 px-3 bg-secondary jamms-clickable-el text-white '
                                                if(this.state.activePackage === index){
                                                    classNameView = classNameView.split('bg-secondary').join(' ')
                                                    classNameView += 'jamms-bg-secondary border border-info '
                                                }
                                                if(index === 0){
                                                    classNameView += 'rounded-left'
                                                    return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                                }else if(index === dataPackage.length -1){
                                                    classNameView += 'rounded-right'
                                                    return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                                }else{
                                                    return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                                }
                                            
                                            })
                                        }
                                    
                                        
                                    </div>
                                </div>
                                {
                                    this.state.activePackage === 0 ? <PaketKaos /> : this.state.activePackage === 1 ? <PaketTotebag /> : <PaketBrandKit />
                                }
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PricelistPage

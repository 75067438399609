import React from 'react'
import ReactGa from 'react-ga'

ReactGa.initialize('UA-173537824-2')

const WithPageView = (WrappedComp , options ={}) =>{
    const trackPageView = page => {
        ReactGa.set({
            page,
            ...options,
        })
        ReactGa.pageview(page);
    };

    return class extends React.Component{
        state = {
            page : null,
        };

        static getDerivedStateFromProps(props, state) {
            const page = props.location.pathname + props.location.search
            if(page !== state.page){
                return page;
            }

            return null
        }

        componentDidMount(){
            const page = this.props.location.pathname + this.props.location.search
            trackPageView(page)
        }

        componentDidUpdate(prevProps) {
            const curentPage = prevProps.location.pathname + prevProps.location.search;
            const nextPage = this.state.page;

            if(curentPage !== nextPage){
                trackPageView(nextPage)
            }
        }

        render (){
            return(
                <WrappedComp {...this.props} />
            )
        }

    }
}

export default WithPageView;
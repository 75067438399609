import React, { Component } from 'react'
import './../Supports/Css/LandingPage.css'
import Murah from './../Supports/Images/money-loss.png'
import Online from './../Supports/Images/buy.png'
import Detail from './../Supports/Images/loupe.png'
import Garansi from './../Supports/Images/guarantee.png'
import Kaos from './../Supports/Images/rating.png'
import Free from './../Supports/Images/free-shipping.png'
import Keunggulan from './../Supports/Images/trophy.png'
import Logo from './../Supports/Images/logo.png'
import PaketKaos from '../Components/PaketKaos'
import PaketTotebag from '../Components/PaketTotebag'
import PaketBrandKit from '../Components/PaketBrandKit'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   faShoppingBag } from '@fortawesome/free-solid-svg-icons'

const dataPackage = ['Kaos','Totebag','Brand Kit']

export class LandingPage extends Component {
    state = {
        activePackage : 0
    }
    render() {
        return (
            
            <div>
                {/* Jumbotron */}
                <div className='jamms-bg-secondary jamms-jumbotron p-5'>
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12 text-center ">
                                <div className='bg-white d-inline-block p-4 jamms-shadow rounded-circle'>
                                    <img src={Logo} alt='logo' width='100px' />
                                </div>
                                <h1 className='jamms-light jamms-font-second jamms-font-70 m-0 p-0 mt-4'>High Quality Cloth Maker</h1>
                                <p className='jamms-light m-0 p-0'>Quality Never Goes Out of Style</p>
                                    <Link to='/order' className='jamms-link'>
                                    <span className='d-inline-block mt-4 jamms-font-12 jamms-bg-main jamms-clickable-el text-white py-2 px-3 rounded jamms-shadow'> 
                                        Pesan Sekarang
                                        <FontAwesomeIcon icon={faShoppingBag} className='ml-2' />
                                    </span>
                                    </Link>
                                   
                            </div>
                        </div>
                    </div>
                </div>

                {/* Why Order With Us */}
                <div className='py-5 bg-light'>
                    <div className="container">
                        <div className='text-center'>
                            <h1 className='jamms-font-second text-center d-inline'>Keunggulan Kami</h1>
                            <p className='text-secondary'>Kenapa Mesti Nyablon di Jamms Artwear ??</p>
                        </div>

                        <div className="row my-5  justify-content-between">
                            <div className="col-md-4  row align-items-center  px-5">
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Online} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                       Pesan Lewat Website, Pantau status pesanan, Tunggu Barang Sampai
                                    </div>
                                </div>
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Murah} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                        Harga Paling Murah di Pasaran, Banyak diskon Menanti Anda
                                    </div>
                                </div>
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Detail} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                       Hasil Sablon detail, Presisi dan Tahan Lama
                                    </div>
                                </div>
                                {/* <img src={Murah} alt="harga-murah"/> */}
                                {/* <img src={Detail} alt="hasil-detail"/> */}
                            </div>
                            <div className="col-md-4 d-none d-md-block ">
                                <img src={Keunggulan} width='100%' alt="keunggulan-kami"/>
                            </div>
                            <div className="col-md-4  row align-items-center px-5">
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Kaos} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                        Kaos dengan bahan berkualitas dan nyaman digunakan
                                    </div>
                                </div>
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Garansi} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                       Garansi uang kembali jika hasil tidak memuaskan
                                    </div>
                                </div>
                                <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                    <div className="col-4 ">
                                        <img src={Free} width='100%' alt="order-online"/>
                                    </div>
                                    <div className="col-8 jamms-font-14 font-weight-light">
                                       Gratis ongkos kirim, syarat dan ketentuan berlaku
                                    </div>
                                </div>
                             
                            </div>
                        </div>


                    </div>
                </div>



                <div ref={(el) => { this.priceList = el; }} className='jamms-package-sablon-container'>
                    <div className="container py-5">
                        <div className='text-center'>
                            <h1 className='jamms-font-second jamms text-center d-inline'>Paket Sablon</h1>
                            <p className='text-secondary'>Paket beragam jenis sablon dengan harga terjangkau !!</p>
                            <div className='mt-4'>
                                <div>
                                    {
                                        dataPackage.map((val,index) => {
                                            var classNameView = 'py-2 px-3 bg-secondary jamms-clickable-el text-white '
                                            if(this.state.activePackage === index){
                                                classNameView = classNameView.split('bg-secondary').join(' ')
                                                classNameView += 'jamms-bg-secondary border border-info '
                                            }
                                            if(index === 0){
                                                classNameView += 'rounded-left'
                                                return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                            }else if(index === dataPackage.length -1){
                                                classNameView += 'rounded-right'
                                                return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                            }else{
                                                return <span onClick={() =>this.setState({activePackage : index})} className={classNameView}>{val}</span>
                                            }
                                           
                                        })
                                    }
                                   
                                    
                                </div>
                            </div>
                            {
                                this.state.activePackage === 0 ? <PaketKaos /> : this.state.activePackage === 1 ? <PaketTotebag /> : <PaketBrandKit />
                            }
                        </div>

                        
                    </div>
                </div>


                {/* Subsrciption Email & Login */}

                <div className="jamms-bg-light py-5 px-3">
                    <div className="container jamms-banner-subscription text-center jamms-light py-5">
                        <div className="row justify-content-center">
                            <div className="col-8 col-md-4">
                                <h3 className='p-0 m-0'>Pusing Hitung Harga??</h3>
                                <p className='p-0 m-0 font-weight-light mt-3'>Yuk coba calculator harga di Jamms</p>
                                <Link to='/order' className='jamms-link'>
                                    <button className='jamms-button-light jamms-clickable-el jamms-shadow mt-4'> Hitung Harga</button>
                                </Link>
                                


                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}

                

            </div>
        )
    }
}

export default LandingPage

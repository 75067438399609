import React, { Component } from 'react'

export class PaketTotebag extends Component {
    render() {
        return (
            <div className="container">
                                <div className="mt-5">
                                    <div className="row justify-content-center">

                                        <div className="col-md-6 px-3 mt-4 mt-md-0">
                                            <div className='rounded jamms-shadow jamms-bg-light py-4'>
                                                <div className='py-3 '>
                                                    <h2 className='jamms-font-second p-0 m-0 jamms-secondary'>Paket Sablon Totabag</h2>
                                                </div>

                                                <div className='pt-3 px-4 jamms-font-14 text-center'>
                                                    <table className='table'>
                                                        <thead className='thead-dark'>
                                                            <tr>
                                                                <th>Jumlah Item</th>
                                                                <th>Harga Per Item</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1-6</td>
                                                                <td>IDR 45K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7-12</td>
                                                                <td>IDR 40K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>13-35</td>
                                                                <td>IDR 38K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>36-70</td>
                                                                <td>IDR 35K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>71-100</td>
                                                                <td>IDR 30K</td>
                                                            </tr>
                                                            <tr>
                                                                <td> {'>'} 100</td>
                                                                <td>Contact Us</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                
                                                    
                                                </div>
                                                <div className=' pb-3 px-4 jamms-font-12 text-secondary text-left'>
                                                    <p className='p-0 m-0  font-weight-light mt-2'>
                                                    - Bahan kain canvas 30 x 40 cm <br/>
                                                    - Tinta Sablon Plastisol <br/>
                                                    - 2 Warna dan 2 Sisi, penambahan warna atau sisi dikenakan biaya 2k <br/>
                                                    </p>
                                                
                                                </div>
                                            
                                            
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>

                            </div>
        )
    }
}

export default PaketTotebag

import React, { Component } from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { SECONDARY_COLOR } from '../Supports/Constants/Color'

export class LoadingPage extends Component {
    render() {
        return (
            <div className="py-5">
                <div className="py-5">
                    <div className="py-5">
                        <div className="py-5">
                            <div className="py-5">
                                <div className="mt-5 text-center">
                                <Loader
                                    
                                    type="ThreeDots"
                                    color={SECONDARY_COLOR}
                                    height={60}
                                    width={60}
                            
                                />
                                loading ...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoadingPage

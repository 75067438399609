export const PriceCalculation = (products,qty,additional=0) => {
    if(products === 'kaos'){
        if(qty <= 6){
            return (qty * 70000) + (additional * qty)
        }else if(qty > 6 && qty <= 12 ){
            return (additional * qty) +  (qty * 65000)
        }else if(qty > 13 && qty <= 35){
            return (additional * qty) +  (qty * 63000)
        }else if(qty > 35 && qty <= 70){
            return (additional * qty) +  (qty * 60000)
        }else {
            return (additional * qty) +  (qty * 56000)
        }
    }else if(products === 'totebag'){
        // alert(additional)
        if(qty <= 6){
            return (additional * qty) + (qty * 45000)
        }else if(qty > 6 && qty <= 12 ){
            return (additional * qty) + (qty * 40000)
        }else if(qty > 13 && qty <= 35){
            return (additional * qty) + (qty * 38000)
        }else if(qty > 35 && qty <= 70){
            return (additional * qty) + (qty * 35000)
        }else {
            return (additional * qty) + (qty * 30000)
        }
    }else if(products === 'brand kit'){
        var total = 0
        var prices = [
            {
                name : "price_tag",
                price : 500
            },
            {
                name : "sablon_merk_kaos",
                price : 2000
            },
            {
                name : "paper_bag_packaging",
                price : 1000
            }
        ]

        Object.keys(qty).forEach((val) => {
            prices.forEach((price) => {
                if(val === price.name){
                    total += price.price * qty[val]
                }
            })
        })
        return total
    }
}
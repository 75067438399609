import React, { Component } from 'react'
import BrandKitForm from '../Components/BrandKitForm'
import KaosForm from '../Components/KaosForm'
import LoadingPage from '../Components/LoadingPage'
import TotebagForm from '../Components/TotebagForm'

export class DetailOrder extends Component {
    state = {
        title : null
    }
    componentDidMount(){
        window.scrollTo(0,0)
        var title = this.props.match.params.jenis
        title = decodeURI(title)
        this.setState({title})
    }
    render() {
        if(this.state.title === null){
            return (
              <LoadingPage />
            )
        }
        if(this.state.title === 'kaos'){
            return <KaosForm />
        }else if(this.state.title === 'totebag'){
            return <TotebagForm />
        }else if(this.state.title === 'brand kit'){
            return <BrandKitForm />
        }
        
    }
}

export default DetailOrder;

import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { PriceCalculation } from '../Functions/PriceCalculate'
import { WhatsappTextGenerator } from '../Functions/WhatsappTextGenerator'
import LoadingPage from './../Components/LoadingPage'
import ReactGa from 'react-ga'

// const data = {
//     // title : 'kaos',
//     // created_at : "05-10-2020",
//     // data : {
//     //     total : {
//     //         s : 0,
//     //         m : 20,
//     //         l : 2,
//     //         xl : 1,
//     //         xxl : 0,
//     //         xxxl : 0
//     //     },
//     //     warna : 1,
//     //     desain :'belum ada',
//     //     jenis_tinta : 'plastisol',
//     //     jenis_lengan : 'pendek',
//     //     bahan_kaos : 'combed 30s'
//     // }
//     title : 'brand kit',
//     created_at : "05-10-2020",
//     data : {
//         total : {
//             price_tag : 10,
//             sablon_merk_kaos : 20,
//             paper_bag_packaging : 10
//         },
//         desain :'ada',
//     }
// }

export class OrderConfirmation extends Component {
    state = {
        data : null
    }
    componentDidMount(){
        this.getData()
    }
    getData = () => {
        var data = localStorage.getItem('order')
        data = JSON.parse(data)
        this.setState({data : data})

    }
    renderDataConfirm = () => {
        return Object.keys(this.state.data.data).map((val) => {
            if(val === 'total'){
                return null
            }
            return(
                <tr>
                    <td className='jamms-font-12 font-weight-light text-capitalize'> {val.split('_').join(' ')} </td>
                    <td className='jamms-font-12 ml-2 text-capitalize pl-4'> {this.state.data.data[val]} </td>
                </tr>
            )
        })
    }

    renderDataKuantitas = () => {
        return Object.keys(this.state.data.data.total).map((val) => {
            if(this.state.data.data.total[val] > 0){
                return (
                    <tr>
                        <td className='jamms-font-12 font-weight-light text-capitalize'> {val.split('_').join(' ')} </td>
                        <td className='jamms-font-12 ml-2 text-capitalize pl-4'> {this.state.data.data.total[val]} </td>
                    </tr>
                )
            }else{
                return null
            }
        })
    }

    calculatePrice = () => {
        if(this.state.data.title !== 'brand kit'){
            var additional = 0
            if(this.state.data.title === 'kaos'){
                if(this.state.data.data.bahan_kaos === 'combed 24s') additional += 5000
                if(this.state.data.data.bahan_kaos === 'cotton bambu') additional += 10000
                if(this.state.data.data.warna_kaos > 2) additional += ( 2000 * (this.state.data.data.warna_kaos - 2))
                if(this.state.data.data.jenis_lengan === 'panjang') additional += 5000
                if(this.state.data.data.jenis_lengan === 'panjang rib') additional += 7000
            }else{
                if(this.state.data.data.jumlah_warna_desain > 2) additional += ( 2000 * (this.state.data.data.jumlah_warna_desain - 2))
            }
            // alert(additional)
            var qty = 0
            Object.keys(this.state.data.data.total).forEach((val) => {
                qty += this.state.data.data.total[val]
            })
            return PriceCalculation(this.state.data.title,qty,additional)
        } else{

            return PriceCalculation(this.state.data.title,this.state.data.data.total)
        }
    }

    onPesanSekarangClick = () => {
        var text = WhatsappTextGenerator(this.state.data)
        text = encodeURI(text)
        ReactGa.event({
            category : "cta",
            action : "click pesan sekarang",
            label : "whatsapp message"
        })

        window.open(`https://wa.me/+6287764906963?text=${text}`,'_blank')

    }
    render() {
        if(this.state.data === null){
            return <LoadingPage />
        }
        return (
            <div className='py-5'>
                 <Helmet>
                    <title>Konfirmasi Orderanmu !!</title>
                </Helmet>
                <div className="py-5">
                    <div className="py-5">
                        <div className="container">
                            <h1 className='text-center jamms-secondary'> Konfirmasi Order Mu </h1>
                            <p className='font-weight-light text-center text-secondary'>Cek kembali data orderan mu sebelum melanjutkan !!</p>

                            <div className="row px-4">
                                <div className="col-md-6">
                                    <div className="border rounded jamms-shadow p-5">
                                        <h4 className='jamms-main-dark text-capitalize'>Data Order {this.state.data.title}</h4>
                                        <table className='mt-3'>

                                            {this.renderDataConfirm()}

                                        </table>

                                        <p className='jamms-main-dark text-capitalize mt-4'>Data Kuantitas</p>
                                        <table className='mt-3'>
                                            {this.renderDataKuantitas()}
                                        </table>

                                    </div>
                                </div>
                                <div className="col-md-6 mt-5 mt-md-0">
                                    <div className="border rounded jamms-shadow p-5">
                                        <h4 className='jamms-main-dark'>Estimasi Harga</h4>
                                        <h5 className='text-danger font-weight-light'>
                                            Rp{' '}
                                            {
                                                this.calculatePrice().toLocaleString('id-ID')
                                            }
                                        </h5>
                                        <span onClick={this.onPesanSekarangClick} className='rounded jamms-font-12 jamms-clickable-el jamms-bg-secondary py-2 px-3 text-white mt-4 d-inline-block'>
                                            Pesan Sekarang
                                            <FontAwesomeIcon icon={faShoppingBag} className='ml-2' />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default OrderConfirmation

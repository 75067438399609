import { faArrowRight, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import chroma from 'chroma-js';
import Select from 'react-select';
import { Helmet } from 'react-helmet';

// import Skeleton from 'react-loading-skeleton';

const JenisTinta = ['plastisol']
const JenisLengan = ['pendek','panjang', 'panjang rib']
const BahanKaos = ['combed 30s','combed 24s','cotton bambu']
const colourOptions = [
    { value: 'hitam', label: 'Hitam', color: 'black' },
    { value: 'putih', label: 'Putih', color: 'lightgrey' },
    { value: 'cream', label: 'Cream', color: '#b0a377' },
    { value: 'coklat', label: 'Coklat', color: 'brown'},
    { value: 'abu muda', label: 'Abu Muda', color: '#b7b5af' },
    { value: 'abu misty', label: 'Abu Misty', color: '#a4a5a9' },
    { value: 'abu tua', label: 'Abu Tua', color: '#1b1b1b' },
    { value: 'oren', label: 'Oren', color: 'orange' },
    { value: 'baby blue', label: 'Baby Blue', color: '#62cefb' },
    { value: 'turkish', label: 'Turkish', color: '#0069b7' },
    { value: 'benhur', label: 'Benhur', color: '#091c57' },
    { value: 'dongker', label: 'Dongker', color: '#0d0f18' },
    { value: 'kuning baby', label: 'Kuning Baby', color: '#e4c852' },
    { value: 'kuning', label: 'Kuning', color: '#eae549' },
    { value: 'merah', label: 'Merah', color: 'red' },
    { value: 'marun', label: 'Marun', color: '#666666' },
    { value: 'hijau', label: 'Hijau', color: 'green' },
    { value: 'army', label: 'Army', color: '#1c2512' },
    { value: 'pink', label: 'Pink', color: 'pink' },
    { value: 'ungu', label: 'Ungu', color: 'purple' }
  ];

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
        color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};




// jumlah warna
// jumlah pesan
// jenis sablon
// ukuran kaos
// jenis lengan



export class KaosForm extends Component {
    state = {
        total : {
            s : 0,
            m : 0,
            l : 0,
            xl : 0,
            xxl : 0,
            xxxl : 0
        }
        ,
        warna : 1,
        hasDesain:true,
        jenisTinta : 'plastisol',
        jenisLengan : 'pendek',
        bahanKaos : 'combed 30s',
        warna_kaos : colourOptions[0]
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }

    handleChangeColor = (selected) => {
        this.setState({warna_kaos : selected})
    }

    onLanjutClick = () => {
        var totalValidate = false
        Object.keys(this.state.total).forEach((val) => {
            if(this.state.total[val] > 0){
                totalValidate = true
            }
        })

        if(totalValidate) {
            
            var data = {
                title : 'kaos',
                created_at : new Date(),
                data : {
                    warna_kaos : this.state.warna_kaos.value,
                    bahan_kaos : this.state.bahanKaos,
                    total : this.state.total,
                    jenis_tinta : this.state.jenisTinta,
                    jenis_lengan : this.state.jenisLengan,
                   
                   
                }
            }

            if(this.state.hasDesain){
                data.data.desain = 'sudah ada'
                data.data.jumlah_warna = this.state.warna
            }else{
                data.data.desain = 'belum ada'
            }

            try {
                localStorage.setItem('order',JSON.stringify(data))
                window.location ='/order/kaos/confirmation'
            } catch (error) {
                alert(error.message)
            }
            
        }else{
            alert('jumlah kaos harus lebih dari 0')
        }
    }
    render() {
        
        return (
            <div className='py-5'>
                <Helmet>
                    <title>Form Pesan Kaos di Jamms</title>
                    <meta name="description" content="Isi form ini untuk memesan kaos lewat website, harga lebih pasti dan transparan, dapatkan gratis ongkir untuk area Purwokerto dan Sekitarnya"/>
                </Helmet>
                <div className="py-5">
                    <div className="container mt-4">
                        <h1 className='jamms-secondary text-center'>Form Bikin Kaos</h1>
                        <p className='font-weight-light text-center text-secondary'>Dapatkan kaos dengan kualitas terbaik disini</p>
                    </div>

                    <div className="container mt-4">
                        <div className="row px-4 justify-content-center">
                            <div className="col-md-6 border jamms-shadow p-5">
                                <h4>Warna Kaos</h4>
                                <Select
                                    value={this.state.warna_kaos}
                                    label="Single select"
                                    options={colourOptions}
                                    styles={colourStyles}
                                    onChange={this.handleChangeColor}
                                />

                                <h4 className='mt-4'>Bahan Kaos</h4>
                               {
                                   BahanKaos.map((val) => {
                                       return(
                                        <div className="form-check">
                                        <input className="form-check-input" name='bahan' onClick={() => this.setState({bahanKaos : val})} type="radio" id={val} value={val} checked={this.state.bahanKaos === val}/>
                                        <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={val}>
                                            {val}
                                        </label>
                                        
                                        </div>
                                       )
                                   })
                               }

                                <h4 className='mt-4'>Jenis Tinta Sablon</h4>
                                {
                                    JenisTinta.map((val) => {
                                        return(
                                            <div className="form-check">
                                            <input className="form-check-input" name='tinta' onClick={() => this.setState({jenisTinta : val})} type="radio" id={val} value={val} checked={this.state.jenisTinta === val}/>
                                            <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={val}>
                                                {val}
                                            </label>
                                            
                                            </div>
                                        )
                                    })
                                }
                                <h4 className='mt-4'>Jenis Lengan</h4>
                                {
                                    JenisLengan.map((val) => {
                                        return(
                                            <div className="form-check">
                                            <input className="form-check-input" name='lengan' onClick={() => this.setState({jenisLengan : val})} type="radio" id={val} value={val} checked={this.state.jenisLengan === val}/>
                                            <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={val}>
                                                {val}
                                            </label>
                                            
                                            </div>
                                        )
                                    })
                                }

                                <h4 className='mt-4'>Jumlah Kaos Dan Ukuran</h4>
                                {
                                    Object.keys(this.state.total).map((val) => {
                                        return(
                                            <div>
                                                <FontAwesomeIcon onClick={() => {
                                                    var total = this.state.total
                                                    if(total[val] >= 10){
                                                        total[val] -=10
                                                        this.setState({total: total})
                                                    }
                                                }} className='jamms-main jamms-clickable-el' icon={faMinusCircle} />
                                                <FontAwesomeIcon onClick={() => {
                                                    var total = this.state.total
                                                    if(total[val] !== 0){
                                                        total[val] --
                                                        this.setState({total: total})
                                                    }
                                                }} className='jamms-main jamms-clickable-el' icon={faMinusCircle} />
                                                
                                                <span className='mx-3 jamms-secondary'>{this.state.total[val]}</span>
                                                <FontAwesomeIcon onClick={() => {
                                                    var total = this.state.total
                                                    total[val] ++
                                                    this.setState({total: total})
                                                }} className='jamms-main jamms-clickable-el' icon={faPlusCircle} />
                                                 <FontAwesomeIcon onClick={() => {
                                                    var total = this.state.total
                                                    total[val] += 10
                                                    this.setState({total: total})
                                                }} className='jamms-main jamms-clickable-el' icon={faPlusCircle} />
                                                <span className='ml-3 text-secondary jamms-font-12 font-weight-light'>
                                                {val.toUpperCase()}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                                <div className="mt-2 text-secondary jamms-clickable-el jamms-font-12">
                                    <a target='_blank' rel="noopener noreferrer" className='jamms-link' href="https://www.instagram.com/p/CD0ni_Ql519">
                                        *Lihat Size Chart 
                                    </a>
                                </div>

                                <h4 className='mt-4'>Sudah Punya Desain ?</h4>

                                <div className="form-check">
                                    <input onClick={() => this.setState({hasDesain : true})} className="form-check-input" name='isdesain' type="radio" id='sudah' value='sudah' checked={this.state.hasDesain}/>
                                    <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={'sudah'}>
                                        {'sudah'}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input onClick={() => this.setState({hasDesain : false})} className="form-check-input" name='isdesain' type="radio" id='belum' value='belum' checked={this.state.hasDesain ===false} />
                                    <label className="form-check-label text-secondary font-weight-light jamms-font-14" for={'belum'}>
                                        {'belum'}
                                    </label>
                                </div>
                                
                                {
                                    this.state.hasDesain ? 
                                    <span>
                                        <h4 className='mt-4'>Jumlah Warna Dalam Desain</h4>
                                        <div>
                                            <FontAwesomeIcon onClick={() => this.state.warna !== 1 && this.setState({warna :   this.state.warna -1})} className='jamms-main jamms-clickable-el'  icon={faMinusCircle}/>
                                            <span className='mx-3 jamms-secondary'>{this.state.warna}</span>
                                            <FontAwesomeIcon onClick={() => this.setState({warna : this.state.warna +1})} className='jamms-main jamms-clickable-el'  icon={faPlusCircle}/>
                                        </div>
                                    </span>
                                    :
                                    false
                                }


                               
                                <div className='mt-5'>
                                <span onClick={this.onLanjutClick}  className='jamms-bg-secondary py-2 px-4 rounded text-white jamms-clickable-el'>
                                    lanjut
                                    <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
                                </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default KaosForm

import React, { Component } from 'react'

export class PaketKaos extends Component {
    render() {
        return (
            <div className="container">
                                <div className="mt-5">
                                    <div className="row justify-content-center">

                                        <div className="col-md-6 px-3 mt-4 mt-md-0">
                                            <div className='rounded jamms-shadow jamms-bg-light py-4'>
                                                <div className='py-3 '>
                                                    <h2 className='jamms-font-second p-0 m-0 jamms-secondary'>Paket Sablon Kaos</h2>
                                                </div>

                                                <div className='pt-3 px-4 jamms-font-14 text-center'>
                                                    <table className='table'>
                                                        <thead className='thead-dark'>
                                                            <tr>
                                                                <th>Jumlah Kaos</th>
                                                                <th>Harga Per Kaos</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1-6</td>
                                                                <td>IDR 70K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7-12</td>
                                                                <td>IDR 65K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>13-35</td>
                                                                <td>IDR 63K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>36-70</td>
                                                                <td>IDR 60K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>71-100</td>
                                                                <td>IDR 56K</td>
                                                            </tr>
                                                            <tr>
                                                                <td> {'>'} 100</td>
                                                                <td>Contact Us</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                
                                                    
                                                </div>
                                                <div className=' pb-3 px-4 jamms-font-12 text-secondary text-left'>
                                                    <p className='p-0 m-0  font-weight-light mt-2'>
                                                    - Bahan Kaos Combed 30's Premium Quality <br/>
                                                    - Untuk Bahan Combed 24's dikenakan biaya tambahan 5k <br/>
                                                    - Tinta Sablon Plastisol <br/>
                                                    - 2 Warna dan 2 Sisi, penambahan warna atau sisi dikenakan biaya 2k <br/>
                                                    - Ukuran S,M,L,XL, Setiap penambahan X dikenakan biaya 5k <br/>
                                                    </p>
                                                
                                                </div>
                                            
                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6 px-3 h-100 mt-4 mt-md-0">
                                            <div className='rounded jamms-shadow h-100 jamms-bg-light py-4'>
                                                <div className='py-3 '>
                                                    <h2 className='jamms-font-second p-0 m-0 jamms-secondary'>Penambahan Harga</h2>
                                                </div>
                                                <div className='pt-3 px-4 jamms-font-14 text-center'>
                                                    <table className='table'>
                                                        <thead className='thead-dark'>
                                                            <tr>
                                                                <th>Penambahan</th>
                                                                <th>Harga Per Kaos</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lengan Panjang Rib</td>
                                                                <td>IDR 7K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Tambah X</td>
                                                                <td>IDR 5K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Warna Misty</td>
                                                                <td>IDR 5K</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Combed 24's</td>
                                                                <td>IDR 5k</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Cotton Bamboo</td>
                                                                <td>IDR 10k</td>
                                                            </tr>
                                                            <tr>
                                                                <td> Tambah Warna</td>
                                                                <td>IDR 2k</td>
                                                            </tr>
                                                            <tr>
                                                                <td> Warna Gold / Metalik </td>
                                                                <td>IDR 7k</td>
                                                            </tr>
                                                            <tr>
                                                                <td> Sablon Raster</td>
                                                                <td>IDR 5k</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                
                                                    
                                                </div>
                                            
                                            
                                                {/* <div className="py-5">
                                                    <button className='jamms-button-secondary jamms-clickable-el jamms-shadow'> Order Now </button>
                                                </div> */}
                                            </div>
                                        </div>

                                        
                                    
                                        
                                    </div>
                                </div>

                            </div>
        )
    }
}

export default PaketKaos
